import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  // {
  //   path: 'started',
  //   loadChildren: () => import('./started/started.module').then( m => m.StartedPageModule)
  // },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  // {
  // //   path: 'payment-method',
  // //   loadChildren: () => import('./payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  // // },
  {
    path: 'settlement-complete',
    loadChildren: () => import('./settlement-complete/settlement-complete.module').then( m => m.SettlementCompletePageModule)
  },
  {
    path: 'kioskautologin/:id/:uuid/:tid',
    loadChildren: () => import('./kioskautologin/kioskautologin.module').then( m => m.KioskautologinPageModule),
    pathMatch: 'full'
  },  
  {
    path: 'please-select',
    loadChildren: () => import('./please-select/please-select.module').then( m => m.PleaseSelectPageModule)
  },
  {
    path: 'started1',
    loadChildren: () => import('./started1/started1.module').then( m => m.Started1PageModule)
  },
  {
    path: 'tv-screen',
    loadChildren: () => import('./tv-screen/tv-screen.module').then( m => m.TvScreenPageModule)
  },
  {
    path: 'product-combo-detail',
    loadChildren: () => import('./product-combo-detail/product-combo-detail.module').then( m => m.ProductComboDetailPageModule)
  },
  {
    path: 'autologin/1',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: '**',
    redirectTo: 'home'
 },
  {
    path: 'name-modal',
    loadChildren: () => import('./name-modal/name-modal.module').then( m => m.NameModalPageModule)
  }

 



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
